/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { PaginatedResponse } from '@eagle/api-types';
import { Geofence, GeofenceType, Group, LastThingEvent, Thing, ThingType } from '@eagle/core-data-types';
import {
  API_CALL_TEXT_LENGTH,
  AppliedFilter,
  AppliedFilterType,
  BoldMatchedText,
  CacheDataTypes,
  entityGroup,
  ErrorPage,
  FetchOneOfAll,
  FilterDataTypes,
  filterDeletedCache,
  FilterFieldNewProps,
  FilterPathIdentifiers,
  filterToQuery,
  FilterTypes,
  FILTER_OUT,
  FindAddressProps,
  FindItemsDeferredResult,
  FindItemsResult,
  getListResultDescription,
  HERE_MAP_API_KEY,
  LastThingStateProvider,
  ListPageQuery,
  ListSearchProvider,
  LoadingComponent,
  MapDiscoverItem,
  MapLayersProvider,
  MapPage, MapProvider,
  MapStorageKeys,
  MiddleSpinner,
  MiddleSpinnerOverlay,
  NewFilter,
  NEW_FILTER_FLAG,
  ReplacePath,
  savedPositionCheck,
  SAVED_FILTER_KEY,
  SearchMapResults,
  ThingEntities,
  ThingSearchController,
  THING_FILTER_STORAGE_KEY,
  T_MANY,
  T_ONE,
  Undefinable,
  useAuthenticated,
  useBoolFlag,
  useConfig,
  useFetchAllCache,
  useFlags,
  useMapContext,
  usePromise,
  useSavedPosition,
  useSmallScreen,
  useTitle,
  wrapLongitude
} from '@eagle/react-common';
import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Axios from 'axios';
import L from 'leaflet';
import { useSnackbar } from 'notistack';
import { FC, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThingLastLocationRow } from './thing-last-location-row';
import { ThingMapNavigator } from './thing-map-navigator';
import { ThingPopup } from './thing-popup';

const DEFAULT_MAP_CENTER = L.latLng(0, 0);
const ADDRESS_LIMIT_COUNT = 2;
const SEARCH_PADDING = 0.5;

export const ThingMap: FC = () => {
  const { userInfo } = useAuthenticated();

  return <ThingMapInner key={userInfo.accountId} />;
};

const FILTER_REPLACE_PATHS: ReplacePath[] = [
  {
    old: FilterTypes.ID,
    new: FilterTypes.THING,
  },
];

const ThingMapInner: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { axios, userInfo } = useAuthenticated();
  const { savedPosition, setSavedPosition } = useSavedPosition(MapStorageKeys.THING_MAP);
  const { t } = useTranslation(['common', 'track', 'terms']);
  const flags = useFlags();
  const showGeofenceSearch = useBoolFlag('portals-geofence-feature');

  useTitle(t('track:page.thing-map.title'));
  useEffect(() => {
    savedPositionCheck(MapStorageKeys.THING_MAP);
  }, []);

  const config = useConfig();
  const savedCenter = savedPosition && new L.LatLng(savedPosition.lat, savedPosition.lng);
  const smallScreen = useSmallScreen();

  const [loading, setLoading] = useState(!savedPosition?.timeStamp);
  const [locationLoading, setLocationLoading] = useState(false);

  const groupCache = useFetchAllCache(CacheDataTypes.GROUP);
  const [groups, groupError, groupState] = usePromise(
    filterDeletedCache<Group>(groupCache),
    [groupCache],
  );

  const thingTypesCache = useFetchAllCache(CacheDataTypes.THING_TYPE);
  const [thingTypes, thingTypesError, thingTypesState] = usePromise(
    filterDeletedCache<ThingType>(thingTypesCache),
    [thingTypesCache],
  );

  const findAddresses = ({ bounds, search }: FindAddressProps): FindItemsDeferredResult<MapDiscoverItem> => {
    const cancelToken = Axios.CancelToken.source();

    if (!search || search.length < API_CALL_TEXT_LENGTH) {
      return {
        cancel: () => cancelToken.cancel(),
        promise: Promise.resolve({
          result: {
            itemCount: 0,
            results: [],
          },
          resultDescription: t('common:component.search.hint.less-than-count', { count: API_CALL_TEXT_LENGTH }),
        }),
      };
    }

    return {
      cancel: () => cancelToken.cancel(),
      promise: Axios.get<{ items: MapDiscoverItem[] }>('https://discover.search.hereapi.com/v1/discover', {
        cancelToken: cancelToken.token,
        params: {
          apiKey: config.hereMaps?.apiKey ?? HERE_MAP_API_KEY,
          in: `bbox:${wrapLongitude(bounds.west - SEARCH_PADDING)},${bounds.south - SEARCH_PADDING},${wrapLongitude(bounds.east + SEARCH_PADDING)},${bounds.north + SEARCH_PADDING}`,
          lang: 'en',
          limit: ADDRESS_LIMIT_COUNT,
          q: search,
        },
      }).then((response) => {
        return {
          result: {
            itemCount: 0,
            results: response.data.items,
          },
          resultDescription: '',
        };
      }),
    };
  };

  const getThingLastLocation = async (id: string): Promise<Undefinable<LastThingEvent>> => {
    const response = await axios.get<PaginatedResponse<LastThingEvent>>(`/api/v2/last-thing-event/thing/${id}`, {
      params: {
        filter: { 'feature': 'tracking', 'eventTypeId': 'location-update' },
        limit: 1,
        sort: { updated: 'desc' },
      },
    });

    if (!response.data.items.length || response.status !== 200) return undefined;

    return response.data.items[0];
  };

  const findGeofences = ({ filters, pagination, search }: ListPageQuery): FindItemsDeferredResult<Geofence> => {
    const cancelToken = Axios.CancelToken.source();

    if (search.length < API_CALL_TEXT_LENGTH || !showGeofenceSearch) {
      return {
        cancel: () => cancelToken.cancel(),
        promise: Promise.resolve({
          result: {
            itemCount: 0,
            results: [],
          },
          resultDescription: '',
        }),
      };
    }

    return {
      cancel: () => cancelToken.cancel(),
      promise: axios.get<Geofence[]>('/api/v1/geofence', {
        cancelToken: cancelToken.token,
        params: { ...pagination, search, filter: { ...filterToQuery(filters), ...FILTER_OUT.deleted } },
      }).then((response) => {
        const headers = response.headers as Record<string, any>;
        const matchCount = Number.parseInt(headers['x-match-count'] as string, 10) || 0;
        const resultDescription = getListResultDescription({ count: matchCount, entityKey: 'common:terms.geofence', filters, search, t });

        return {
          result: {
            itemCount: matchCount,
            results: response.data,
          },
          resultDescription,
        };
      }),
    };
  };

  const findThings = ({ filters, pagination, search }: ListPageQuery): FindItemsDeferredResult<Thing> => {
    const cancelToken = Axios.CancelToken.source();

    if (search.length < API_CALL_TEXT_LENGTH) {
      return {
        cancel: () => cancelToken.cancel(),
        promise: Promise.resolve({
          result: {
            itemCount: 0,
            results: [],
          },
          resultDescription: '',
        }),
      };
    }

    return {
      cancel: () => cancelToken.cancel(),
      promise: axios.get<Thing[]>('/api/v1/thing', {
        cancelToken: cancelToken.token,
        params: { ...pagination, ...(search ? { search } : {}), filter: { ...filterToQuery(filters, FILTER_REPLACE_PATHS), ...FILTER_OUT.deleted } },
      }).then((response) => {
        const headers = response.headers as Record<string, any>;
        const matchCount = Number.parseInt(headers['x-match-count'] as string, 10) || 0;
        const resultDescription = getListResultDescription({ count: matchCount, entityKey: 'terms:thing', filters, search, t });

        return {
          result: {
            itemCount: matchCount,
            results: response.data,
          },
          resultDescription,
        };
      }),
    };
  };

  const renderThingItem = (item: Thing, handleDrawerClose: () => void, searchQuery?: string): JSX.Element => {
    const { onItemSelected } = useMapContext();

    return (
      <ThingLastLocationRow
        getThingLastLocation={getThingLastLocation}
        handleDrawerClose={handleDrawerClose}
        onItemClicked={onItemSelected}
        searchQuery={searchQuery}
        thing={item}
      />
    );
  };

  const renderGeofenceItem = (item: Geofence, searchQuery?: string): JSX.Element => {
    return (
      <FetchOneOfAll
        dataType={CacheDataTypes.GEOFENCE_TYPE}
        id={item.geofenceTypeId}
        renderFactory={(geofenceType: GeofenceType) => (
          <Stack direction="row">
            <BoldMatchedText query={searchQuery ?? ''} text={`${item.display} - ${geofenceType.display}`} />
          </Stack>
        )}
      />
    );
  };

  const renderAddressItem = (item: MapDiscoverItem): JSX.Element => (
    <Stack direction="row">
      <Typography noWrap>{item.title}</Typography>
    </Stack>
  );

  const renderListContent = (
    items: {
      addresses?: FindItemsResult<MapDiscoverItem>;
      geofences?: FindItemsResult<Geofence>;
      things?: FindItemsResult<Thing>;
    },
    isLoading: boolean,
    handleDrawerClose: () => void,
    highlightIndex?: number,
    searchQuery?: string,
  ): JSX.Element => (
    <SearchMapResults<Thing, MapDiscoverItem, Geofence>
      handleDrawerClose={handleDrawerClose}
      handleFormatAddressItem={renderAddressItem}
      handleFormatGeofenceItem={renderGeofenceItem}
      handleFormatListItem={renderThingItem}
      highlightIndex={highlightIndex}
      initialInstructions={t('common:component.lookup.hint.initial')}
      isLoading={isLoading}
      noResultsInstructions={t('common:common.hint.list.no-results')}
      searchQuery={searchQuery}
      searchResults={items}
      selectedItem={null}
    />
  );

  const renderFilterContent = (
    filters: AppliedFilter<AppliedFilterType>[],
    setFilterOpen: (value: boolean) => void,
    onFiltersChanged: (filters: AppliedFilter<AppliedFilterType>[]) => unknown,
  ): JSX.Element => {
    const filterFields: FilterFieldNewProps[] = [
      {
        apiUrl: '/api/v1/thing',
        attributes: {
          typeCache: thingTypesCache,
          typePath: FilterTypes.THING_TYPE,
        },
        dataType: FilterDataTypes.API,
        fieldLabel: t('common:component.filter.labels.search-a-thing'),
        pathIdentifier: FilterPathIdentifiers.THING,
        propertyLabel: t('terms:thing', { count: T_MANY }),
        typePropertyName: FilterTypes.THING,
      },
      {
        dataType: FilterDataTypes.CACHE,
        entityCache: groupCache,
        fieldLabel: t('common:component.filter.labels.select-a-group'),
        pathIdentifier: FilterPathIdentifiers.GROUP,
        propertyLabel: t('common:terms.group', { count: T_MANY }),
        typePropertyName: FilterTypes.GROUP,
      },
    ];

    return (
      <NewFilter
        filterFields={filterFields}
        filters={filters}
        onCloseClicked={() => setFilterOpen(false)}
        onFiltersChanged={onFiltersChanged}
        savedFilterKey={SAVED_FILTER_KEY}
        storageKey={THING_FILTER_STORAGE_KEY}
        data-testid="thing-map-new-filter"
      />
    );
  };

  const searchFilterComponent = (
    <Box sx={{ alignItems: 'flex-start', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
      <ThingSearchController
        filterFields={[
          {
            entityTypes: thingTypes ?? [],
            propertyLabel: t('common:common.labels.type'),
            typePropertyName: FilterTypes.THING_TYPE,
          },
          {
            entityTypes: groups ? entityGroup(groups) : [],
            propertyLabel: t('common:terms.group', { count: T_ONE }),
            typePropertyName: FilterTypes.GROUP,
          },
        ]}
        onAddressQueryChanged={findAddresses}
        onGeofenceQueryChanged={findGeofences}
        onThingQueryChanged={findThings}
        renderListContent={renderListContent}
        renderFilterContent={flags[NEW_FILTER_FLAG] ? renderFilterContent : undefined}
        storageKey={THING_FILTER_STORAGE_KEY}
        tFunction={t}
      />
    </Box>
  );

  const handleNoLocations = useCallback(() => {
    enqueueSnackbar(t('track:page.thing-map.hint.no-things-to-show'), { variant: 'info', preventDuplicate: true });
  }, [enqueueSnackbar, t]);

  const renderThingEntities = useMemo(() => {
    return (
      <ThingEntities
        key={userInfo.accountId}
        loading={loading}
        savedPosition={savedPosition}
        setLoading={setLoading}
        setLocationLoading={setLocationLoading}
        setSavedPosition={setSavedPosition}
        handleNoLocations={handleNoLocations}
        handleLoadingError={() =>
          enqueueSnackbar(t('track:page.thing-map.hint.loading-error'), { variant: 'error', preventDuplicate: true })
        }
      />
    );
  }, [userInfo.accountId, savedPosition, handleNoLocations]);

  if (thingTypesState === 'pending' || groupState === 'pending') return <MiddleSpinner />;
  if (groupError) return <ErrorPage error={groupError} />;
  if (thingTypesError) return <ErrorPage error={thingTypesError} />;

  return <>
    {!smallScreen && <LoadingComponent isLoading={!loading && locationLoading} />}
    <MiddleSpinnerOverlay fade={loading}>
      <Box padding="0 4" textAlign="center">
        <Typography component="span">{t('track:page.thing-map.loading.hint.prefix')}</Typography>
        <Typography component="span" sx={{ fontWeight: 500 }}> {t('terms:thing', { count: T_MANY })} </Typography>
        <Typography component="span">{t('track:page.thing-map.loading.hint.suffix')}</Typography>
      </Box>
    </MiddleSpinnerOverlay>
    <LastThingStateProvider>
      <MapProvider>
        <ListSearchProvider dataKey="thing-map">
          <MapLayersProvider displayThingsLayerSelection>
            <MapPage
              center={savedCenter ?? DEFAULT_MAP_CENTER}
              data-testid="map-page"
              storageKey={MapStorageKeys.THING_MAP}
              zoom={savedPosition?.alt}
            >
              <ThingMapNavigator getThingLastLocation={getThingLastLocation} />
              {renderThingEntities}
              <Suspense fallback={<MiddleSpinner />}>
                <ThingPopup />
                {searchFilterComponent}
              </Suspense>
              {smallScreen && <LoadingComponent isLoading={!loading && locationLoading} />}
            </MapPage>
          </MapLayersProvider>
        </ListSearchProvider>
      </MapProvider>
    </LastThingStateProvider>
  </>;
};
